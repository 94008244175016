import React, { useRef, useState } from 'react';
import { FormHelperText, Typography } from '@mui/material';
import { CloseIcon, DownloadButton, PdfIcon, ImageIcon } from '../../assets/common-svg/CommonSvg';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../store/reducers/snackBar';
import DownloadImageApi from '../../services/downloadImageService';

export const CustomMultiUpload = ({ readOnly, label, accept, placeholder, name, handleChange, error, touched, helperText, value, InputId, handleRemoveFiles, showHeaderLabel = true }) => {
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const [loadingId, setLoadingId] = useState(null); // Tracks the ID of the file being downloaded

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleButtonClick = (event) => {
        event.stopPropagation();
        handleClick();
    };

    const handleDownload = async (id, name) => {
        setLoadingId(id);
        try {
            const imgRes = await DownloadImageApi.getDownloadedImageul(id);
            const response = await fetch(imgRes?.data || "");

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const contentType = response.headers.get("content-type");
            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(new Blob([blob], { type: contentType }));

            const hasExtension = /\.\w{2,4}$/.test(name);
            const extension = contentType.split("/")[1];
            const fileName = hasExtension ? name : `${name}.${extension}`;

            const link = document.createElement("a");
            link.href = blobUrl;
            link.setAttribute("download", fileName);

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error("Download failed:", error);
            dispatch(setSnackbar({ value: true, message: 'Download failed. Please try again later.', color: "error" }));
        } finally {
            setLoadingId(null); // Reset loading ID after download is complete
        }
    };

    return (
        <div className="w-100">
            {showHeaderLabel && <div className="text-black text-sm font-normal font-['Public Sans'] pb-2">{label}</div>}
            {!readOnly && (
                <div className="w-100 border rounded-md justify-between items-center cursor-pointer flex">
                    <div className="flex justify-start items-center gap-2.5 flex-1" onClick={handleClick}>
                        <div className="mt-2">
                            <label htmlFor="fileInput" className="text-neutral-500 text-base flex gap-3 font-normal pl-2 font-['Public Sans']">
                                <PdfIcon />
                                <p className="align-self-center cursor-pointer">{placeholder}</p>
                            </label>
                            <input
                                id="fileInput"
                                name={name}
                                type="file"
                                className="hidden"
                                accept={accept || "application/pdf, image/*"}
                                ref={fileInputRef}
                                onChange={(e) => {
                                    const file = e.target.files[0];
                                    const maxSize = 20 * 1024 * 1024;
                                    if (file && file.size > maxSize) {
                                        dispatch(setSnackbar({ value: true, message: 'File size exceeds 20 MB. Please choose a smaller file.', color: "error" }));
                                        e.target.value = '';
                                    } else {
                                        handleChange(e);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <button type="button" className="p-2 bg-blue-500 text-white rounded-md mr-2" onClick={handleButtonClick}>
                        {label}
                    </button>
                </div>
            )}
            <div>
                <div className="w-100 mt-2 border !mb-2 rounded-md justify-between items-center">
                    {value && value.length !== 0 ? (
                        value.map((op, index) => (
                            <div key={op.name} className="flex justify-content-between">
                                <Typography key={op.name} variant="body1" className="mb-2 ml-2 mt-2 flex gap-6 align-self-center">
                                    {op.type === "application/pdf" ? <PdfIcon /> : <ImageIcon />}
                                    <p className="align-self-center">{op.name}</p>
                                    {!readOnly && (
                                        <p className="align-self-center cursor-pointer" onClick={() => handleRemoveFiles(index)}>
                                            <CloseIcon fill="red" />
                                        </p>
                                    )}
                                </Typography>
                                {readOnly && (
                                    <button
                                        key={op.path}
                                        onClick={() => handleDownload(op.id, op.name)}
                                        className={`mr-3  ${loadingId === op.id ? "opacity-50" : "cursor-pointer opacity-100"}`}
                                        disabled={loadingId === op.id}
                                    >
                                        
                                            <DownloadButton />
                                       
                                    </button>
                                )}
                            </div>
                        ))
                    ) : (
                        <Typography className="text-center p-4" variant="body1">
                            No Files here
                        </Typography>
                    )}
                </div>
            </div>
            {touched && error && (
                <FormHelperText error id={`standard-weight-helper-text-${InputId}`}>
                    {helperText}
                </FormHelperText>
            )}
        </div>
    );
};
